import React, { useEffect, useRef, useState } from "react";
import logo from "../logo.png";
import "../assets/css/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faYoutube,
  faFacebook,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { faUserPlus, faUsers, faClipboardCheck, faComments, faCog, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { faSkype, faTelegram } from '@fortawesome/free-brands-svg-icons';

import images from './loadImages';
// import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

const HomePage = () => {
  const [activeTab, setActiveTab] = useState("Our Services");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const [tabName, setTabName] = useState('jobSeeker');

  const footerRef = useRef();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const categories = [
  //   {
  //     title: "ENGINEERING",
  //     image: logo,
  //     description:
  //       "From civil engineers to software engineers, we understand the engineering landscape and can help you find the right talent for your projects."
  //   },
  //   {
  //     title: "SALES AND MARKETING",
  //     image: logo,
  //     description:
  //       "We know how vital sales and marketing are to business success. Our specialists will connect you with the sales and marketing professionals who can drive your organization forward."
  //   },
  //   {
  //     title: "HEALTHCARE",
  //     image: logo,
  //     description:
  //       "The healthcare industry demands exceptional talent, and we're here to ensure your medical institution has access to skilled physicians, nurses, and administrative staff."
  //   },
  //   {
  //     title: "FINANCE AND ACCOUNTING",
  //     image: logo,
  //     description:
  //       "Our financial experts understand the intricacies of this field, enabling us to match finance and accounting professionals with companies seeking financial stability and growth."
  //   }
  // ];

  const candidatesFaq = [
    {
      que: "What types of jobs do you offer?",
      answer:"We offer a wide range of job opportunities in various industries, including engineering, sales and marketing, healthcare, finance and accounting, and many more."
    },
    {
      que: "Is there a fee to use your services?",
      answer:"Our services are free for job seekers. We are compensated by employers to find the right candidates for their open positions."
    },
    {
      que: "How long does the recruitment process take?",
      answer:"The recruitment process varies depending on the job and the employer. It can take seven days. We will keep you informed throughout the process."
    },
    {
      que: "Can I apply for multiple jobs?",
      answer:"Yes, you can apply for multiple jobs that match your skills and experience. We encourage you to explore all opportunities that interest you."
    },
    {
      que: "What should I expect after submitting my application?",
      answer:"After submitting your application, our recruiters will review your resume and match your skills with available job openings. If you are shortlisted, we will contact you for an initial interview."
    },
    {
      que: "Do you offer temporary or contract positions?",
      answer:"Yes, we offer a variety of employment types, including temporary, contract, and permanent positions."
    },
    {
      que: "How can I improve my chances of getting hired?",
      answer:"To improve your chances, ensure your resume is up-to-date, tailor your application to each job, and be prepared for interviews. Our recruiters can also provide guidance and support throughout the process."
    },
    
  ];
  const employersFaq = [
    {
      que: "What services do you offer to employers?",
      answer:"We offer comprehensive recruitment services, including candidate sourcing, screening, interviewing, and background checks. We also provide temporary staffing, contract staffing, and permanent placement services."
    },
    {
      que: "How do you find candidates for our open positions?",
      answer:"We use a variety of methods to find candidates, including our extensive database, job boards, social media, and professional networks. We also conduct proactive outreach to attract top talent."
    },
    {
      que: "What is your process for screening candidates?",
      answer:"Our screening process includes reviewing resumes, conducting initial interviews, and performing background checks. We assess candidates based on their skills, experience, and cultural fit for your organization."
    },
    {
      que: "How long does it take to fill a position?",
      answer:"The time to fill a position varies depending on the job requirements and market conditions. On average, it can take seven days. We work diligently to find the best candidates as quickly as possible."
    },
    {
      que: "Do you offer a guarantee on your placements?",
      answer:"Yes, we offer a guarantee period for our placements. If a candidate does not meet your expectations within the agreed timeframe, we will find a replacement at no additional cost."
    },
    {
      que: "How do you ensure the confidentiality of our recruitment process?",
      answer:"We adhere to strict confidentiality policies to protect your company's information and the privacy of candidates. All data is handled with the utmost care and security."
    },
  ];
  const recruitmentAdvantages = [
    {
        title: "Expertise",
        description: "Our team of experienced recruiters has in-depth knowledge of various industries and job markets."
    },
    {
        title: "Personalized Service",
        description: "We take the time to understand your unique needs and tailor our services to meet your specific requirements."
    },
    {
        title: "Quality Candidates",
        description: "We have an extensive network of skilled professionals and access to top talent."
    },
    {
        title: "Time-Saving",
        description: "Our efficient recruitment process saves you time and resources."
    },
    {
        title: "Cost-Effective",
        description: "Our competitive pricing and flexible packages ensure you get the best value for your investment."
    },
    {
        title: "Proven Track Record",
        description: "Our success stories and client testimonials speak for themselves."
    },
    {
        title: "Dedicated Support",
        description: "Our team provides exceptional support throughout the recruitment process and beyond."
    }
  ];

  const manpowerServices = {
      educationCategories: [
          {
              category: "Scientific Disciplines",
              qualifications: ["Life Sciences", "Physical Sciences", "Health Sciences", "Earth and Environmental Sciences", "Formal Sciences"]
          },
          {
              category: "Engineering Diplomas and Degrees",
              qualifications: ["Various Engineering Fields (Diploma and BE)"]
          },
          {
              category: "Undergraduate Programs",
              qualifications: ["Arts and Humanities", "Commerce", "Science", "Engineering", "Management and Business", "Law", "Medical and Health Sciences"]
          },
          {
              category: "Vocational Training",
              qualifications: ["Industrial Training Institute (ITI)", "1-2 Years of Vocational Training in Specific Trades or Skills"]
          }
      ],
      jobCategories: [
          {
              category: "Non-Technical Professionals",
              jobs: ["Accountants", "Sales and Marketing Specialists", "Managers"]
          },
          {
              category: "Information Technology Specialists",
              jobs: ["Computer Operators", "MIS Executives", "SEO Specialists"]
          },
          {
              category: "Engineering and Technical Experts",
              jobs: ["Engineers (All Disciplines)", "Planning Engineers", "Design Engineers", "Production Engineers", "Quality Engineers"]
          },
          {
              category: "Safety and Environmental Professionals",
              jobs: ["Safety Supervisors", "Firemen", "Fire Officers"]
          },
          {
              category: "Logistics and Operations Staff",
              jobs: ["Delivery Riders", "Ground Staff", "Station Associates"]
          }
      ],
      nonDegreeJobs: [
          {
              educationLevel: "8th Grade Level Jobs",
              jobs: ["Helper", "Peon", "Housekeeping Staff"]
          },
          {
              educationLevel: "10th Grade Level Jobs",
              jobs: ["Data Entry Operator", "Receptionist", "Clerk"]
          },
          {
              educationLevel: "12th Grade Level Jobs",
              jobs: ["Administrative Assistant", "Human Resources Assistant", "Marketing Coordinator"]
          }
      ]
  };

  const services = [
    {
      title: 'Recruitment',
      description: 'In today\'s dynamic job market, finding the right talent can be challenging. Staffing Mart, a leading recruitment agency in India, offers comprehensive recruitment services at competitive prices. Our team specializes in end-to-end recruitment, ensuring that you find the perfect candidates for your organization in a timely manner. We excel in Senior Level recruitment, also known as "Executive Search," which is critical for filling top positions with candidates possessing the specific skills and expertise needed for success.',
      icon: faUserPlus,
    },
    {
      title: 'Staffing',
      description: 'Managing fluctuating workforce demands can be tough in an ever-changing economy. Staffing Mart provides flexible staffing solutions that cater to your short-term and long-term needs. Whether you require temporary staffing to handle peak periods or permanent staffing for sustained growth, our agency offers cost-effective and timely solutions. Our team is experienced in finding the right fit for your company, ensuring seamless integration of new hires into your existing workforce.',
      icon: faUsers,
    },
    {
      title: 'Assessment',
      description: 'Selecting the right candidate goes beyond just resumes and interviews. At Staffing Mart, we offer robust assessment services to evaluate candidates\' skills, competencies, and cultural fit. Our comprehensive assessment tools and methodologies ensure that you onboard individuals who not only meet the technical requirements but also align with your organization\'s values. This thorough evaluation process helps reduce hiring risks and ensures long-term success for your team.',
      icon: faClipboardCheck,
    },
    {
      title: 'Consulting',
      description: 'Navigating the complexities of human resources requires expert guidance. Staffing Mart offers professional HR consulting services to help you optimize your talent acquisition and workforce management strategies. Our consultants bring a wealth of knowledge and experience to the table, providing tailored advice that addresses your unique challenges. Whether you\'re looking to improve your recruitment processes, enhance employee engagement, or develop leadership, our consulting services deliver actionable insights and results.',
      icon: faComments,
    },
    {
      title: 'Outsourcing',
      description: 'In a competitive business landscape, focusing on core operations is crucial. Staffing Mart offers outsourcing services that take care of your entire HR functions, from recruitment to payroll and employee management. By entrusting us with these critical tasks, you can reduce operational burdens and costs while ensuring compliance and efficiency. Our outsourcing solutions are designed to be flexible, scalable, and aligned with your business objectives, allowing you to concentrate on what matters most.',
      icon: faCog,
    },
    {
      title: 'Training',
      description: 'The success of any organization depends on the continuous development of its people. Staffing Mart provides tailored training programs designed to enhance the skills and knowledge of your employees. From onboarding new hires to developing leadership capabilities, our training services cover all aspects of professional growth. We focus on delivering practical and impactful training that drives performance and prepares your workforce to meet future challenges. Whether you need technical training or soft skills development, we have the expertise to elevate your team.',
      icon: faChalkboardTeacher,
    }
  ];
  
const CategoryList = ({ categories }) => (
  categories.map((category, index) => (
    <div className="category-item" key={index}>
      <div className="image_wrapper">
        <img
          src={category.image}
          alt={category.title}
          className="category-image"
        />
      </div>
      <h2 className="category-title">{category.title}</h2>
      <p className="category-description">{category.description}</p>
    </div>
  ))
);
  // faq
  const faQ = (num) => {
    console.log(num, 'num');
    isOpen === num ? setIsOpen(null) : setIsOpen(num);
  }

  useEffect(() => {
    // Function to update footer height..
    const updateFooterHeight = () => {
      if (footerRef.current) {
        const height = footerRef.current.clientHeight;
        setFooterHeight(height);
      }
    };

    updateFooterHeight();

    // Update footer height on window resize..
    window.addEventListener('resize', updateFooterHeight);

    // Clean up event listener..
    return () => {
      window.removeEventListener('resize', updateFooterHeight);
    };
  }, []);

  const tab = (tab) => {
    setTabName(tab);
  }



  return (
    <>
      <section className="staffing_mart_sec" style={{height: `calc(100vh - ${footerHeight}px)`}}>
        <div className="container">
          {/* staffing row */}
          <div className="staffing_row_wrap">
            <div className="left-section">
              {/* logo */}
              <div className="staffing_mart_logo">
                <img src={logo} alt="Staffing Mart" className="logo" />
              </div>

              <h1 className="title primary-text">
                Connecting Talent with Opportunity
              </h1>

              {/* content */}
              <div className="sm_content_wrapper">
                <p className="description primary-text">
                  At <strong>Staffing Mart</strong>, we believe that the right
                  job can transform a person's life, and the right person can
                  transform a business.
                </p>
                <p className="description primary-text">
                  Our mission is to bridge the gap between talented
                  professionals and thriving companies, ensuring a perfect fit
                  for both.
                </p>
              </div>

              <div className="sm_detail_wrapper">

                {/* Contact Us */}
                <div className="sm_contact_us">
                  <h3 className="social-title primary-text">Contact Us</h3>

                  <a href="tel:+919081699700" className="button">
                    <FontAwesomeIcon icon={faPhone} /> +91 9081699700
                  </a>
                </div>

                {/* social media - Follow Us */}
                <div className="social-media">
                  <h3 className="social-title primary-text">Follow Us</h3>
                  <div className="social-icons">
                    <a
                      href="mailto:hrmstaffingmart@gmail.com"
                      className="social-icon"
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                    <a
                      href="https://wa.me/919081699700?text=Hello,%20I’m%20looking%20for%20new%20job%20opportunities%20and%20would%20love%20to%20explore%20how%20your%20placement%20agency%20can%20help%20me%20find%20the%20right%20role.%20Could%20you%20please%20share%20more%20information%20about%20your%20services%20and%20the%20current%20openings%20you%20have?%20Thank%20you!"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a
      href="skype:live:yourusername?call"
      className="social-icon"
    >
      <FontAwesomeIcon icon={faSkype} />
    </a>
    <a
      href="https://t.me/yourusername"
      target="_blank"
      rel="noopener noreferrer"
      className="social-icon"
    >
      <FontAwesomeIcon icon={faTelegram} />
    </a>
                    <a
                      href="https://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                      href="https://www.youtube.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a
                      href="https://www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </div>
                </div>

              </div>
            </div>

            <div className="right-section">
              <div className="phone-frame">
                {/* header - menu */}
                <div className="phone-header">
                  <div className="tab active">{activeTab}</div>
                  <div className="menu tab" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                </div>

                {isMenuOpen && (
                  <div className="dropdown-menu">
                    <div
                      className={`tab ${
                        activeTab === "Our Services" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Our Services")}
                    >
                     Our Services
                    </div>
                    <div
                      className={`tab ${
                        activeTab === "Categories" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Categories")}
                    >
                     Categories
                    </div>
                    <div
                      className={`tab ${
                        activeTab === "Why Choose Us" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Why Choose Us")}
                    >
                      Why Choose Us
                    </div>
                    <div
                      className={`tab ${activeTab === "FAQ" ? "active" : ""}`}
                      onClick={() => handleTabClick("FAQ")}
                    >
                      FAQ
                    </div>
                  </div>
                )}

                {/* content boxes */}
                <div className="content">

                  {/* our services */}
                  {activeTab === "Our Services" && (
                    <div className="categories-section">
                     {/* <h2>Our Services</h2> */}
                        {services.map((service, index) => (
                          <div key={index} className="services-item category-item">
                            <div className="icon-container">
                            <FontAwesomeIcon icon={service.icon} color="#fbb03c" style={{height:"6rem"}}/>
                            </div>
                            <h2 className="category-title">{service.title}</h2>
                            <p className="category-description">{service.description}</p>
                          </div>
                        ))}
                    </div>
                  )}
                  {activeTab === "Categories" && (
                    <div className="categories-section">
                     <h2>Education Categories</h2>
                        <CategoryList categories={manpowerServices.educationCategories.map(cat => ({
                          image: images[cat.category+'.png'], // Add appropriate image URLs here
                          title: cat.category,
                          description: cat.qualifications.join(', ')
                        }))} />

                        <h2>Job Categories</h2>
                        <CategoryList categories={manpowerServices.jobCategories.map(cat => ({
                          image: images[cat.category+'.png'],
                          title: cat.category,
                          description: cat.jobs.join(', ')
                        }))} />

                        <h2>Jobs That Do Not Require a College Degree</h2>
                        <CategoryList categories={manpowerServices.nonDegreeJobs.map(cat => ({
                          image: images[cat.educationLevel+'.png'],
                          title: cat.educationLevel,
                          description: cat.jobs.join(', ')
                        }))} />
                    </div>
                  )}

                  {/* faq */}
                  {activeTab === "FAQ" && (
                    <div className="sm_faq_wrapper">

                      <div className="fab_tab_wrapper">
                          <button className={`${tabName === 'jobSeeker' ? 'active' : ''} tab_btn`} onClick={() => tab('jobSeeker')}>Job Seekers</button>
                          <button className={`${tabName === 'employer' ? 'active' : ''} tab_btn`} onClick={() => tab('employer')}>Employers</button>
                      </div>

                      <h2 className="category-title">{tabName === 'jobSeeker' ? 'Job Seekers' : 'Employers'}</h2>

                      {
                        tabName === 'jobSeeker' && 
                        (
                          <div className="faq_inner_wrap">
                          {candidatesFaq.map((faq, index) => (
                            <div className="faq_queans_wrap" key={index}>
                              <button onClick={() => faQ(index)}>{index+1}. {faq.que}</button>
                              {isOpen === index && <p>{faq.answer}</p>}
                            </div>
                          ))}
                        </div>
                        )
                      }
                      {
                        tabName === 'employer' && 
                        (
                          <div className="faq_inner_wrap">
                            {employersFaq.map((faq, index) => (
                              <div className="faq_queans_wrap">
                                <button onClick={() => faQ(`${index}_emp`)}>{index+1}. {faq.que}</button>
                                {isOpen === `${index}_emp` && <p>{faq.answer}</p>}
                              </div>
                            ))}
                          </div>
                        )
                      }

                    </div>
                  )}

                  {/* Why Choose Us */}
                  {activeTab === "Why Choose Us" && (
                    <div className="why_choose_us_wrap">
                      <h2>Why Choose Us for Your Recruitment Needs?</h2>
                      <p>At Staffing Mart we understand the importance of finding the right talent for your business.</p>
                      <h4>Here's why you should choose us for your recruitment process:</h4>
                      <ul>
                      {recruitmentAdvantages.map((adv, index) => (
                        <li key={index}><strong>{adv.title}:</strong> {adv.description}</li>
                      ))}
                      </ul>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* footer */}
      <footer className="sm_footer" ref={footerRef}>
        <p>&copy; 2024 Your Staffing Mart. All rights reserved.</p>
      </footer>
    </>
  );
};

export default HomePage;
